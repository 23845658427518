import React from "react"
import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

const ContentOnlyPage = ({ data, location }) => {
  const post = data.allContentPagesJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          lang="en"
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="columns content-only-pages">
          <div className="column is-4"></div>
          <div className="column">
            <MarkdownViewer markdown={post.content} />
            {post.buttons && post.buttons.length >= 1 && (
              <ButtonGroupMap buttons={post.buttons} />
            )}
          </div>
          <div className="column is-4"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contentPage($title: String!) {
    allContentPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        content
        buttons {
          button {
            href
            buttonText
            appearance
            destination
          }
        }
      }
    }
  }
`

export default ContentOnlyPage
